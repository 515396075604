.footer {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 255, 162, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: "flex-start";
  align-items: "flex-start";
  flex: 1;
  width: 100%;
  height: 100%;
  padding-top: 250px;
  padding-left: 250px;
}
.footer-content h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: rgb(0, 0, 0);
}
.footer-content span {
  font-size: 0.89rem;
  margin-bottom: 1rem;
  letter-spacing: 1px;
  color: rgb(0, 0, 0);
  width: 50%;
}
.footerBtn {
  background-color: transparent;
  padding: 10px 14px;
  cursor: pointer;
  border-radius: 10px;
  outline: none;
  border: 2px solid black;
  font: 16px sans-serif;
  font-weight: bold;
  color: black;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  transition: 0.3s;
}
.footerBtn:hover{
  transition: 0.3s;
  background-color: black;
  color: white;
}
.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top: 50px;
}
.footerLine {
  width: 70%;
  height: 3px;
  background-color: black;
}
@media only screen and (min-device-width: 300px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .footer{
    height: 100%;
    width: 100%;
    margin-top: 250px;
  }
  .footer-content h1 {
    font-size: 2rem;}
  .footer-content {
    padding-top: 100px;
    padding-left: 90px;
    padding-bottom: 100px;
  }
  .contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    margin-top: 60px;
  }
  .footerLine {
    margin-bottom: 3em;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
 .footer-content{
    padding-top: 100px;
    padding-left: 110px;
    padding-bottom: 100px;
 }
 .footer {
  height: 50vh;}
}